<template>
  <div>
    <a-modal
      v-model="isShowFirstStepModal"
      centered
      :width="800"
      :mask-closable="false"
      ok-text="下一步"
      :ok-button-props="{ props: { loading: firstStepSubmitting } }"
      title="续期"
      @ok="handleValidate"
    >
      <a-row>
        <a-form
          class="iotplt-compact-form"
          :form="firstStepForm"
          @submit="handleValidate"
        >
          <a-col :span="24">
            <a-form-item
              :wrapper-col="{ span: 24 }"
            >
              <common-card-input
                :rows="15"
                ref="iccidInput"
                v-decorator="['iccids', {
                  initialValue: { data: this.iccids },
                  rules: [{ validator: checkIccids }],
                }]"
              />
            </a-form-item>
          </a-col>
        </a-form>
      </a-row>
    </a-modal>

    <a-modal
      v-model="isShowSubmitModal"
      centered
      :width="600"
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting, disabled: !isCanSubmit } }"
      title="续期"
      @ok="handleSubmit"
    >
      <a-row>
        <a-form
          class="iotplt-compact-form"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          :form="submitForm"
          @submit="handleSubmit"
        >
          <a-form-item label="已选择卡数">
            <a-input
              disabled
              v-model="this.validIccids.length"
            />
          </a-form-item>

          <a-form-item label="续期周期数量">
            <a-input-number
              :step="1"
              style="width: 100%"
              @change="handleCyclesChange"
              v-decorator="['cycles', {
                rules: [
                  { validator: validateCycles }
                ]
              }]"
            />
          </a-form-item>

          <a-form-item label="续期金额(元)">
            <a-spin :spinning="loadingTotalFee">
              <a-input
                disabled
                v-decorator="['total_fee', {
                  rules: [
                    { validator: validateRenewTotalFee }
                  ]
                }]"
              />
            </a-spin>
          </a-form-item>

          <a-form-item
            label="可用额度(元)"
            extra="可用额度=信用额度+账户余额-锁定金额"
          >
            <a-input
              disabled
              v-model="this.availableFund"
            />
          </a-form-item>

          <a-form-item label="备注">
            <a-textarea
              v-decorator="['remark', {
                rules: [
                  { max: 200, message: '最多200个字符' },
                ]
              }]"
              :auto-size="{ minRows: 3, maxRows: 5 }"
            />
          </a-form-item>
        </a-form>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import {
  renewCards, renewCardsCalculateFee,
  validateRenewSimCards
} from '@/api/batch_operation'
import CommonCardInput from '@/components/CardInput/Common'
import { findAgentFinanceOverview } from '@/api/agent'

export default {
  name: 'BatchOperationTopAgentRenewCards',
  components: { CommonCardInput },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    iccids: {
      type: Array,
      default() {
        return []
      }
    },
    renewSource: {
      type: String,
      default() {
        return 'agent_sim_card_list'
      }
    }
  },
  data() {
    this.calculateRenewTotalFee = this.$lodash.debounce(this.calculateRenewTotalFee, 400)
    const isShowFirstStepModal = this.visible
    return {
      availableFund: 0, // 可用额度
      loadingTotalFee: false,
      firstStepSubmitting: false,
      submitting: false,
      validIccids: [],
      isShowFirstStepModal: isShowFirstStepModal,
      isShowSubmitModal: false,
      isCanSubmit: false, // 是否可以提交续期
      firstStepForm: this.$form.createForm(this, { name: 'renew_first_step' }),
      submitForm: this.$form.createForm(this, { name: 'renew_submit' })
    }
  },
  watch: {
    // 步骤中任何一个弹窗打开，则 isShow 应为 true
    isShowFirstStepModal(val) {
      this.isShow = val || this.isShowSubmitModal
    },

    isShowSubmitModal(val) {
      this.isShow = val || this.isShowFirstStepModal
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },

    agentId() {
      return this.$store.getters.userAgentId
    }
  },
  created() {
    this.fetchAvailableFund()
  },
  methods: {
    // 获取可用额度
    fetchAvailableFund() {
      findAgentFinanceOverview(this.agentId).then((res) => {
        if (res.code === 0) {
          this.availableFund = res.data.available
        }
      })
    },

    checkIccids(rule, value, callback) {
      if (!value || !value.inputValue) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请输入卡号')
        return
      }

      if (value.validateStatus === 'error') {
        callback(value.errorMsg)
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    validateCycles(rule, value, callback) {
      if (value === undefined || value === null || value === '') {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请输入续期周期数量')
        return
      }

      if (!/^[1-9]\d*$/.test(value)) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请输入 1-9999 之间的整数')
        return
      }

      value = parseInt(value)
      if (!value || value < 1 || value > 9999) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请输入 1-9999 之间的整数')
        return
      }

      callback()
    },

    validateRenewTotalFee(rule, value, callback) {
      if (value === undefined || value === null || value === '') {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请等待续期金额计算完成')
        return
      }

      if (value > this.availableFund) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('账户可用额度低于续期金额，请充值后再进行续期')
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    // 清空续期金额
    clearRenewTotalFee() {
      this.submitForm.setFieldsValue({
        total_fee: undefined
      })
    },

    // 处理续期周期数量变更
    handleCyclesChange() {
      this.isCanSubmit = false
      this.clearRenewTotalFee()
      this.calculateRenewTotalFee()
    },

    // 计算续期金额
    calculateRenewTotalFee() {
      this.submitForm.validateFields(['cycles'], { force: true }, (err, values) => {
        if (!err) {
          this.loadingTotalFee = true
          renewCardsCalculateFee({ iccids: this.validIccids, cycles: values.cycles }).then((res) => {
            if (res.code === 0 && res.data.cycles === values.cycles) {
              if (res.data.is_can_renew) {
                this.submitForm.setFieldsValue({
                  total_fee: res.data.total_fee
                })
                this.isCanSubmit = true
              } else {
                this.$error({
                  title: '提示',
                  content: res.data.error
                })
              }
            }
            this.loadingTotalFee = false
          })
        }
      })
    },

    // 验证输入的 iccids
    handleValidate(e) {
      e.preventDefault()
      if (this.firstStepSubmitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.firstStepForm.validateFields((err, values) => {
        if (!err) {
          this.firstStepSubmitting = true
          validateRenewSimCards({ iccids: values.iccids.data, renew_source: this.renewSource }).then((res) => {
            if (res.code === 0) {
              if (res.data.is_valid) {
                // 关闭模态框
                this.isShowSubmitModal = true
                this.validIccids = values.iccids.data
                this.isShowFirstStepModal = false
              } else {
                this.firstStepForm.setFieldsValue({
                  iccids: { inputValue: res.data.result }
                })
                this.$nextTick(() => {
                  this.$refs.iccidInput.handleCards()
                })
              }
            }
            this.firstStepSubmitting = false
          })
        }
      })
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.submitForm.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          renewCards({
            ...values,
            top_agent_total_fee: values.total_fee,
            iccids: this.validIccids
          }).then((res) => {
            if (res.code === 0) {
              this.isShowSubmitModal = false
              // 告知父组件已完成
              this.$emit('completed')
              this.$success({
                title: '提交成功',
                content: (
                  <div>
                    <p>操作成功，具体操作结果请至 【系统查询】-【批量操作查询】 查看</p>
                  </div>
                )
              })
            } else {
              this.fetchAvailableFund()
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
